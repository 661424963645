import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Column, EditableTable } from '@timeedit/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  activitiesLoadingSelector,
  activitiesPaginationSelector,
  activityGroupBySelector,
  activityOverviewSelector,
  changeExpandedRows,
  changeSelectedActivityIds,
  changeTablePagination,
  fetchActivitiesInTrack,
  fetchTracksInSeries,
  selectedActivitiesSelector,
  activityExpandedRowsSelector,
} from 'activities/pages/slices/activity.slice';
import { ColumnTitle } from '@timeedit/ui-components/lib/src/components/EditableTable/ColumnTitle';
import intl, { getInlineString } from 'i18n/intl';
import Tag from '@timeedit/ui-components/lib/src/components/Tag/Tag';
import { ActivityEvents, TActivityRowRecord } from 'activities/pages/types/activity.type';
import TEObjectsService from 'activities/services/TEObjects.service';
import ExpandIcon from './ExpandIcon';
import ActivitiesTableFooter from './ActivitiesTableFooter';
import './ActivitiesTable.scss';
import { BranchesOutlined } from '@ant-design/icons';
import ActivityValueCell from './ActivityValueCell';
import { Table, Typography } from 'antd';
import { compact } from 'lodash';
import { TableRowSelection } from 'antd/es/table/interface';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';

const language = intl.messages;

export default function ActivitiesTable({ type }: { type: 'ACTIVITY_SERIES' | 'FLAT' }) {
  const dispatch = useDispatch();
  const overview = useSelector(activityOverviewSelector);
  const selectedRowKeys = useSelector(selectedActivitiesSelector);
  const loading = useSelector(activitiesLoadingSelector);
  const pagination = useSelector(activitiesPaginationSelector);
  const activityGroupBy = useSelector(activityGroupBySelector);
  const expandedRows = useSelector(activityExpandedRowsSelector);
  const tableId = useMemo(() => `activitiesTable_${type}`, [type]);
  const isNested = useMemo(() => type === 'ACTIVITY_SERIES', [type]);

  const columns = useMemo(
    (): Column<TActivityRowRecord>[] =>
      compact([
        isNested && {
          ...(Table.EXPAND_COLUMN as Column<TActivityRowRecord>),
          width: 32,
        },
        {
          title: (
            <ColumnTitle
              title={
                overview.series?.[0] ? (
                  <ActivityValueCell activityValue={overview.rowData?.[overview.series?.[0]?.id]?.primaryObject} />
                ) : (
                  (language['activities.overview.table.primary_object'] as string)
                )
              }
            />
          ),
          width: 200,
          key: 'primaryObject',
          render: (rowData: TActivityRowRecord) => {
            if (isNested && rowData.level !== 'series') return null;
            return <ActivityValueCell activityValue={overview.rowData[rowData.id]?.primaryObject} />;
          },
          resizable: true,
        },
        {
          title: <ColumnTitle title={language['activities.overview.table.activity_type'] as string} />,
          width: 180,
          key: 'activityType',
          render: (rowData: TActivityRowRecord) => {
            if (!isNested) {
              return <ActivityValueCell activityValue={overview.rowData[rowData.id]?.activityType} />;
            }
            if (rowData.level === 'series')
              return (
                <Button
                  type="link"
                  onClick={() => {
                    document.dispatchEvent(
                      new CustomEvent(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, {
                        detail: {
                          activitySeriesId: rowData.id,
                        },
                      }),
                    );
                  }}
                >
                  <ActivityValueCell activityValue={overview.rowData[rowData.id]?.activityType}>
                    &nbsp;x&nbsp;{overview.rowData[rowData.id]?.numberOfTracks}
                  </ActivityValueCell>
                </Button>
              );
            if (rowData.level === 'track') {
              return (
                <div>
                  <BranchesOutlined style={{ transform: 'rotate(90deg)', fontSize: 16 }} />
                  &nbsp;
                  <Button
                    onClick={() => {
                      document.dispatchEvent(
                        new CustomEvent(ActivityEvents.OPEN_ACTIVITY_TRACK_DRAWER, {
                          detail: {
                            activitySeriesId: rowData.activitySeriesId,
                            trackId: rowData.trackId,
                          },
                        }),
                      );
                    }}
                    type="link"
                  >
                    {rowData.children?.length}
                  </Button>
                </div>
              );
            }
            return null;
          },
          resizable: true,
        },
        {
          title: <ColumnTitle title={language['activities.overview.table.weeks'] as string} />,
          width: 250,
          key: 'weeks',
          render: (rowData: TActivityRowRecord) => {
            if (!overview.rowData[rowData.id]?.weeks) return 'N/A';
            const weekDisplay = `w ${overview.rowData[rowData.id]?.weeks}`;
            if (!rowData.level || rowData.level === 'activity')
              return (
                <Button
                  type="link"
                  onClick={() => {
                    document.dispatchEvent(
                      new CustomEvent(ActivityEvents.OPEN_ACTIVITY_DRAWER, {
                        detail: {
                          activityId: rowData.id,
                        },
                      }),
                    );
                  }}
                >
                  {weekDisplay}
                </Button>
              );
            return `w ${overview.rowData[rowData.id]?.weeks}`;
          },
          resizable: true,
        },
        {
          title: <ColumnTitle title={language['activities.overview.table.duration'] as string} />,
          width: 120,
          key: 'duration',
          render: (rowData: TActivityRowRecord) => {
            return overview.rowData[rowData.id]?.duration;
          },
        },
        {
          title: <ColumnTitle title={language.status as string} />,
          width: 120,
          key: 'status',
          render: (rowData: TActivityRowRecord) =>
            overview.rowData[rowData.id]?.activityStatuses.map((status: string, statusIdx: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Tag key={`status_${statusIdx}`}>{status.replace(/_/g, ' ')}</Tag>
            )),
        },
        ...overview.allOtherValues.map((col: string) => ({
          title: <ColumnTitle title={TEObjectsService.getObjectTypeLabel(col, TEObjectsService.getFieldLabel(col))} />,
          width: 250,
          resizable: true,
          key: `activity_${col}`,
          render: (rowData: TActivityRowRecord) => {
            return <ActivityValueCell activityValue={overview.rowData[rowData.id]?.[col]} />;
          },
        })),
      ]),
    [overview],
  );

  const onPaginationChange = useCallback(
    (page?: number, perPage?: number) => {
      dispatch(
        changeTablePagination({
          page,
          perPage,
        }),
      );
    },
    [dispatch],
  );

  const handleRowSelection: TableRowSelection<unknown>['onChange'] = (selectedKeys) => {
    dispatch(
      changeSelectedActivityIds([
        ...(selectedRowKeys || []).filter((key) => !allRowIds.includes(key)),
        ...(selectedKeys as string[]),
      ]),
    );
  };

  const dataSources = useMemo(() => {
    return isNested ? overview.series : overview.activities;
  }, [type, overview]);

  const activityActionsDisabled = useMemo(() => {
    return dataSources?.some(
      (item) =>
        selectedRowKeys?.includes(item.id) &&
        // always use activity status in new exam flow
        overview.rowData[item.id]?.activityStatuses?.includes(EActivityStatus.IN_REVIEW),
    );
  }, [selectedRowKeys, dataSources]);

  const allRowIds = useMemo(() => {
    const getChildrenKey = (items?: TActivityRowRecord[]): string[] => {
      if (!items) return [];
      return items.flatMap((item) => {
        return [item.id, ...getChildrenKey(item.children)];
      });
    };
    return getChildrenKey(dataSources);
  }, [dataSources]);

  const selectedRowIds = useMemo(() => {
    return selectedRowKeys?.filter((id) => allRowIds.includes(id));
  }, [selectedRowKeys, dataSources]);

  return (
    <div
      className={`activity-series-table te-flex te-flex-col te-flex-1 ${isNested ? 'activity-series-table--nested-table' : ''}`}
      data-testid="ACTIVITIES_TABLE"
    >
      <div className="header">
        <Typography.Text>
          {getInlineString(
            activityGroupBy === 'ACTIVITY_SERIES'
              ? 'activities.overview.table.showing_number_of_activities'
              : 'activities.overview.table.showing_number_of_activities_flat',
            dataSources?.length ?? 0,
            pagination.totalActivities,
          )}
        </Typography.Text>
      </div>
      <EditableTable
        id={tableId}
        loading={loading}
        columns={columns}
        dataSource={dataSources}
        rowKey="id"
        tableLayout="fixed"
        expandable={{
          columnWidth: 32,
          showExpandColumn: true,
          expandIcon: ({ expanded, onExpand, record }) => {
            return <ExpandIcon expanded={expanded} onExpand={onExpand} record={record} />;
          },
          expandedRowKeys: expandedRows,
          onExpand: (expanded: boolean, record: TActivityRowRecord) => {
            dispatch(changeExpandedRows({ rowId: record.id, expanded }));
            if (expanded) {
              if (record.level === 'series') {
                dispatch(fetchTracksInSeries(record.id));
              } else if (record.level === 'track' && record.activitySeriesId && record.trackId) {
                dispatch(fetchActivitiesInTrack(record.activitySeriesId, record.trackId));
              }
            }
          },
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRowIds,
          checkStrictly: false,
          onChange: handleRowSelection,
          columnWidth: 32,
          getCheckboxProps: (record) => {
            if (!isNested) return {};
            if (record.level === 'track')
              return {
                style: {
                  transform: 'translateX(32px)',
                },
              };
            if (!record.level || record.level === 'activity')
              return {
                style: {
                  transform: 'translateX(64px)',
                },
              };
            return {};
          },
        }}
        pagination={false}
        footer={() => (
          <ActivitiesTableFooter
            activityActionsDisabled={activityActionsDisabled}
            onPaginationChange={onPaginationChange}
          />
        )}
      />
    </div>
  );
}

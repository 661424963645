import React, { useEffect, useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { useActivityTrackGetter } from './ActivityDrawer.hook';
import { ActivityInfo } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks } from './ActivityValues';
import intl from 'i18n/intl';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import {
  deleteActivitiesById,
  updateActivitiesValues,
  updateActivitySeriesTrackWeeks,
} from 'activities/services/activities.service';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import ActivityTags from './ActivityTags';
import ActivityWeeks from './ActivityWeeks';
import TEObjectsService from 'activities/services/TEObjects.service';
import { Button, Flex, Modal, notification, Popconfirm, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { TWeekSelectorValue } from '../WeekSelector/WeekSelector';

const language = intl.messages as Record<string, string>;

function ActivityTrackDrawer() {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const organizationId = useSelector(organizationIdSelector);
  const [activitySeriesId, setActivitySeriesId] = useState<undefined | string>(undefined);
  const [trackId, setTrackId] = useState<undefined | number>(undefined);
  const {
    activitySeries,
    loading,
    activityValues,
    multipleValuesExtIds,
    initialValues,
    allDateRanges,
    triggerToRefetchSeries,
  } = useActivityTrackGetter({
    activitySeriesId,
    trackId,
  });
  const [modal, modalContextHolder] = Modal.useModal();

  const editable = useMemo(() => {
    if (!activitySeries) return false;
    return activitySeries.activityStatuses.every(
      (status) => ![EActivityStatus.IN_REVIEW].includes(status as EActivityStatus),
    );
  }, [activitySeries]);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activitySeries || !activitySeries.formId) return;
    setSaving(true);
    await updateActivitiesValues(
      activitySeries.formId.toString(),
      activitySeries.activityIds,
      EActivityGroupings.FLAT,
      updatedValues,
    );
    setSaving(false);
    if (typeof onClose === 'function') {
      onClose(true);
    }
  };

  const onOpen = (e: Event) => {
    setOpen(true);
    const { detail } = e as CustomEvent<{ activitySeriesId: string; trackId: number }>;
    setActivitySeriesId(detail.activitySeriesId);
    setTrackId(detail.trackId);
  };

  const onClose = (updated?: boolean) => {
    setOpen(false);
    document.dispatchEvent(
      new CustomEvent(ActivityEvents.CLOSE_ACTIVITY_TRACK_DRAWER, {
        detail: {
          activitySeriesId,
          updated,
        },
      }),
    );
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_TRACK_DRAWER, onOpen);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_TRACK_DRAWER, onOpen);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setActivitySeriesId(undefined);
      setTrackId(undefined);
    }
  }, [open]);

  const onDeleteConfirm = async () => {
    if (organizationId && activitySeries?.activityIds) {
      await deleteActivitiesById(organizationId, activitySeries?.activityIds);
      if (typeof onClose === 'function') {
        onClose(true);
      }
    }
  };

  const onRequestToChangeWeeks = (weeks: TWeekSelectorValue[], callback?: (_weeks: TWeekSelectorValue[]) => void) => {
    return new Promise((resolve, reject) => {
      modal.confirm({
        title: language['activities.confirm_changing_weeks_of_series.title'],
        content: language['activities.confirm_changing_weeks_of_series.content'],
        onOk: async () => {
          try {
            if (organizationId && activitySeriesId) {
              await updateActivitySeriesTrackWeeks(organizationId, activitySeriesId, trackId!, weeks);
              triggerToRefetchSeries();
            }
            if (typeof callback === 'function') callback(weeks);
            resolve(undefined);
          } catch (err) {
            reject(err);
          }
        },
        onCancel: () => reject(new Error('Reject to submit weeks change, keep editing')),
      });
    });
  };

  return (
    <ActivityDrawerWrapper
      saving={saving}
      open={open}
      onClose={() => onClose()}
      title={`${activitySeries?.primaryObject ? TEObjectsService.getObjectLabel(activitySeries?.primaryObject) : 'N/A'} - #${trackId}`}
      subtitle={language.track}
      initialValues={initialValues}
      primaryObject={activitySeries?.primaryObject}
      editable={editable}
      onSave={onSave}
      loading={loading}
    >
      <>
        <ActivityInfo editable={false} loading={loading} activityType={activitySeries?.activityType} />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activityValues}
          title={`Track #${trackId} info`}
          multipleValuesExtIds={multipleValuesExtIds}
        >
          <div className="te-mb-2">
            <Flex gap="small">
              <Button
                size="small"
                onClick={() => notification.info({ message: 'This feature is not implemented yet' })}
              >
                <CopyOutlined /> {language.duplicate_track}
              </Button>
              <Popconfirm
                title={language['activities.confirm_to_delete_track']}
                trigger={['click']}
                onConfirm={onDeleteConfirm}
              >
                <Button size="small">
                  <DeleteOutlined /> {language.delete_track}
                </Button>
              </Popconfirm>
            </Flex>
            <p className="te-mt-2">
              <Typography.Text>{language['activities.warning_when_editing_activities_in_track']}</Typography.Text>
            </p>
          </div>
          <ActivityTracks track={activitySeries?.numberOfTracks} />
          <ActivityWeeks
            editable
            allowMultiple
            allWeeks={allDateRanges}
            numberOfTracks={activitySeries?.numberOfTracks}
            disabledWeeks={[]}
            onSubmit={onRequestToChangeWeeks}
          />
        </ActivityValues>
        <ActivityTags formId={activitySeries?.formId?.toString()} editable={editable} />
        {modalContextHolder}
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivityTrackDrawer;

import { useEffect, useMemo, useState, useContext } from 'react';
import {
  TActivity2Be,
  TActivity2Fe,
} from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { getActivityById } from 'activities/services/activities.service';
import { useSelector } from 'react-redux';
import { IState } from 'types/state.interface';
import { compact, isEmpty, uniq, groupBy, omit } from 'lodash';
import * as activityService from 'activities/services/activities.service';
import {
  TActivityResultsInResponseGroupByActivitySeries,
  TActivityRawValue,
  TActivityResultsInResponseGroupByFlat,
  TActivityResultsInResponseGroupByTrack,
} from 'activities/pages/types/activity.type';
import { toActivityWeekRange } from 'activities/utils';
import { SUBMISSION_VALUE_TYPE } from '@timeedit/activity-manager-shared-lib/lib/internal/types/schedulingEnum.type';
import { TWeekSelectorValue } from '../WeekSelector/WeekSelector';
import { activityOverviewSelector } from 'activities/pages/slices/activity.slice';
import { AMSocketContext } from 'services/am-socket.service';

const activityCategoryToObjectFilterValues = (categories: { id: string; values: string[] }[]) => {
  return categories?.map(({ id, values }) => ({
    fieldId: id,
    values,
  }));
};

export const toActivityInitialValues = ({
  activityType,
  duration,
  startDate,
  endDate,
  values,
  tags,
  weeks,
}: {
  activityType: string;
  duration?: number;
  startDate?: string;
  endDate?: string;
  values: TActivity2Fe['values'];
  tags?: TActivity2Fe['tags'];
  weeks?: (number | number[])[];
}): Record<string, TActivityRawValue> => {
  const activityValue: Record<string, TActivityRawValue> = {
    activityType: compact([activityType]),
    ...values.reduce(
      (results: Record<string, TActivity2Fe['values'][number]['value']>, val: TActivity2Fe['values'][number] | any) => {
        const { submissionValueType } = val;
        if (submissionValueType === SUBMISSION_VALUE_TYPE.FILTER) {
          return {
            ...results,
            [val.extId]: JSON.stringify({
              value: val.value.categories ? [] : val.value,
              filters: activityCategoryToObjectFilterValues(val.value.categories || val.submissionValue.categories),
              valueType: SUBMISSION_VALUE_TYPE.FILTER,
            }),
          };
        }
        return {
          ...results,
          [val.extId]: val.value,
        };
      },
      {},
    ),
  };
  if (duration !== undefined) {
    activityValue.duration = duration;
  }
  if (weeks !== undefined) {
    activityValue.weeks = weeks;
  }
  if (startDate && endDate) {
    activityValue.weeks = toActivityWeekRange(startDate, endDate);
  }
  if (tags) {
    activityValue.tags = tags.map((tag) => ({
      value: tag._id.toString(),
      label: tag.name,
    }));
  }
  return activityValue;
};

export const toUniqActivityValues = (series: TActivityResultsInResponseGroupByActivitySeries) => {
  const activityValues = series?.allValues?.map(({ activityValue }) => activityValue) ?? [];
  const extIdsInValues: string[] = [];
  const hasMultipleValues: string[] = [];
  const hasValues: string[] = [];
  activityValues.forEach((item, itemIndex: number) => {
    if (extIdsInValues.includes(item.extId)) {
      hasMultipleValues.push(item.extId);
    } else {
      extIdsInValues.push(item.extId);
    }
    if (hasValues.includes(item.extId)) {
      delete activityValues[itemIndex];
    }
    if (!isEmpty(item.value)) {
      hasValues.push(item.extId);
    }
  });
  if (uniq(series.durations).length > 1) {
    hasMultipleValues.push('duration');
  }
  return { activityValues, hasMultipleValues };
};

/**
 * NOTES:
 * - `weeks` is just for showing. Do not watch and use it when calling to update activities
 * - `dateRanges` is used for basic activity, will be watched as an Form.Item
 */

export const useActivityGetter = ({ activityId }: { activityId: string }) => {
  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [activity, setActivity] = useState<undefined | TActivityResultsInResponseGroupByFlat>();
  const [initialValues, setInitialValues] = useState<undefined | Record<string, TActivityRawValue>>();
  const [activitiesInSameTracks, setActivitiesInSameTracks] = useState<TActivity2Be[]>([]);
  const overview = useSelector(activityOverviewSelector);

  useEffect(() => {
    if (activityId) {
      if (overview.triggers.ids.length && !overview.triggers.ids.includes(activityId)) return;

      const doGetActivity = async () => {
        let activity = overview.rowData[activityId] as TActivityResultsInResponseGroupByFlat;

        if (!activity) {
          setLoading(true);
          if (!organizationId || !activityId) {
            setActivity(undefined);
            setLoading(false);
            return;
          }
          const result = await getActivityById(organizationId, activityId);
          activity = result.activity;
        }
        if (activity && activity.metadata) {
          const ssp = {
            groupBy: 'FLAT',
            matchType: 'ALL',
            page: 1,
            filters: {
              activitySeriesId: {
                values: [activity.activitySeriesGroup?.activitySeriesId],
              },
              track: {
                values: [activity.track],
              },
            },
          };
          setLoading(true);
          const activitiesInSameTracks: TActivity2Be[] = (await activityService.getActivities(organizationId!, ssp))
            .results;
          if (activitiesInSameTracks) {
            setActivitiesInSameTracks(activitiesInSameTracks.filter(({ _id }) => _id.toString() !== activity._id));
          }

          const initialValues = toActivityInitialValues({
            activityType: activity.metadata.activityType,
            duration: activity.metadata.length,
            startDate: activity?.metadata.startDate,
            endDate: activity?.metadata.endDate,
            values: activity.values,
            tags: activity.tags,
          });
          setInitialValues({
            ...omit(initialValues, ['weeks']),
            dateRanges: initialValues.weeks,
          });
        }

        setActivity(activity);
        setLoading(false);
      };
      doGetActivity();
    } else {
      setActivity(undefined);
    }
  }, [activityId, organizationId, overview.triggers.tracker]);

  return {
    activity,
    initialValues,
    activitiesInSameTracks,
    loading,
  };
};

export const useActivitySeriesGetter = ({ activitySeriesId }: { activitySeriesId?: string }) => {
  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const overview = useSelector(activityOverviewSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [activitySeries, setActivitySeries] = useState<undefined | TActivityResultsInResponseGroupByActivitySeries>();
  const [activityValues, setActivityValues] = useState<undefined | TActivity2Fe['values']>();
  const [multipleValuesExtIds, setMultipleValuesExtIds] = useState<string[]>([]);
  const [allDateRanges, setAllDateRanges] = useState<TWeekSelectorValue[][]>([]);

  useEffect(() => {
    if (!organizationId || !activitySeriesId) return;

    if (overview.triggers.ids.length && !overview.triggers.ids.includes(activitySeriesId)) return;

    const doGetActivitySeries = async () => {
      let series = overview.rowData[activitySeriesId] as TActivityResultsInResponseGroupByActivitySeries;
      if (!series) {
        const ssp = {
          groupBy: 'ACTIVITY_SERIES',
          matchType: 'ALL',
          page: 1,
          limit: 1,
          filters: {
            activitySeriesId: {
              values: [activitySeriesId],
            },
          },
        };
        series = (await activityService.getActivities(organizationId, ssp)).results?.[0];
      }

      const activitiesSsp = {
        groupBy: 'FLAT',
        matchType: 'ALL',
        page: 1,
        filters: {
          activitySeriesId: {
            values: [activitySeriesId],
          },
        },
      };
      setLoading(true);

      const activitiesInSeries: TActivity2Be[] = (
        await activityService.getActivities(organizationId, {
          ...activitiesSsp,
          groupBy: 'FLAT',
          limit: 100,
        })
      ).results;

      const allDateRanges = Object.values(
        groupBy(
          activitiesInSeries.map((activity) => ({
            track: activity.track,
            dateRanges: toActivityWeekRange(activity.metadata.startDate, activity.metadata.endDate),
          })),
          'track',
        ),
      );
      setAllDateRanges(
        allDateRanges.map((item) => {
          return item.map((sub) => {
            return sub.dateRanges[0];
          });
        }),
      );
      setActivitySeries(series);
      const { activityValues, hasMultipleValues } = toUniqActivityValues(series);
      setActivityValues(activityValues as TActivity2Be['values']);
      setMultipleValuesExtIds(hasMultipleValues);
      setLoading(false);
    };
    doGetActivitySeries();
  }, [activitySeriesId, overview.triggers.tracker]);

  const initialValues = useMemo(() => {
    const durations = uniq(compact(activitySeries?.durations));
    return toActivityInitialValues({
      activityType: activitySeries?.activityType!,
      duration: durations[0],
      tags: activitySeries?.tags || [],
      weeks: activitySeries?.startEndDates?.flatMap((item) => toActivityWeekRange(item.startDate, item.endDate)) ?? [],
      values: activityValues || [],
    });
  }, [activitySeries, activityValues]);

  return {
    activitySeries,
    activityValues,
    initialValues,
    allDateRanges,
    multipleValuesExtIds,
    loading,
  };
};

export const useActivityTrackGetter = ({
  activitySeriesId,
  trackId,
}: {
  activitySeriesId?: string;
  trackId?: number;
}) => {
  const organizationId = useSelector((state: IState) => state.auth.user?.organizationId);
  const overview = useSelector(activityOverviewSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [activitySeries, setActivitySeries] = useState<undefined | TActivityResultsInResponseGroupByActivitySeries>();
  const [activityValues, setActivityValues] = useState<undefined | TActivity2Be['values']>();
  const [multipleValuesExtIds, setMultipleValuesExtIds] = useState<string[]>([]);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    if (!organizationId || !activitySeriesId) return;

    const doGettingTrack = async () => {
      let foundTrack = overview.rowData[
        activityService.convertToTrackId(activitySeriesId, trackId!)
      ] as TActivityResultsInResponseGroupByActivitySeries;
      if (!foundTrack) {
        setLoading(true);
        // @TODO: should call another api to get tracl by trackId
        const tracks = await activityService.getTracksInSeries(organizationId, activitySeriesId);
        const foundTrackResponse: TActivityResultsInResponseGroupByTrack = tracks.tracks.find(
          (track: TActivityResultsInResponseGroupByTrack) => track.trackId === trackId,
        );
        if (!foundTrackResponse) return;
        setLoading(false);

        foundTrack = {
          ...foundTrackResponse,
          allValues: foundTrackResponse.allValues.map((trackValue) => ({
            activityValue: trackValue,
          })),
        };
      }

      setActivitySeries(foundTrack);
      const { activityValues, hasMultipleValues } = toUniqActivityValues(foundTrack);
      setActivityValues(activityValues as TActivity2Be['values']);
      setMultipleValuesExtIds(hasMultipleValues);
    };
    doGettingTrack();
  }, [activitySeriesId]);

  const initialValues = useMemo(() => {
    const durations = uniq(compact(activitySeries?.durations));
    return toActivityInitialValues({
      activityType: activitySeries?.activityType!,
      duration: durations[0],
      tags: activitySeries?.tags || [],
      weeks: activitySeries?.startEndDates?.flatMap((item) => toActivityWeekRange(item.startDate, item.endDate)) ?? [],
      values: activityValues || [],
    });
  }, [activitySeries, activityValues]);

  return {
    activitySeries,
    activityValues,
    initialValues,
    allDateRanges: [],
    multipleValuesExtIds,
    loading,
    triggerToRefetchSeries: () => setTrigger(trigger + 1),
  };
};

import React, { useMemo, useState } from 'react';
import { Button, Pagination, Popconfirm, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  activitiesPaginationSelector,
  activityGroupBySelector,
  changeSelectedActivityIds,
  publishActivities,
  selectedActivitiesSelector,
  activitiesDeletingSelector,
  activitiesSendingSelector,
  deleteActivities,
  activityOverviewSelector,
  allActivitySeriesIdsSelector,
} from 'activities/pages/slices/activity.slice';
import intl, { getInlineString } from 'i18n/intl';
import { useSendToReviewPopover } from './ActivityTable.hooks';
import { generateFormInstances, triggerDownloadActivities } from 'activities/services/activities.service';
import { organizationIdSelector } from 'slices/auth.slice';
import { DownloadOutlined } from '@ant-design/icons';
import { useAppFeatureFlags } from 'activities/hooks/useAppFeatureFlags';
import PreferencesIcon from '@timeedit/ui-components/lib/src/assets/icons/new/Preferences.svg';
import CoreIcon from '@timeedit/ui-components/lib/src/assets/icons/new/Core.svg';
import { weekRangeChar } from './ActivitiesTable.utils';
import { TActivityResultsInResponse } from 'activities/pages/types/activity.type';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { compact, uniq } from 'lodash';

const language = intl.messages as Record<string, string>;
type TActivitiesTableFooterProps = {
  onPaginationChange: (input: any) => void;
  activityActionsDisabled?: boolean;
};
export default function ActivitiesTableFooter(props: TActivitiesTableFooterProps) {
  const { activityActionsDisabled } = props;
  const { sendToReview } = useAppFeatureFlags();
  const pagination = useSelector(activitiesPaginationSelector);
  const activityGroupBy = useSelector(activityGroupBySelector);
  const selectedRowKeys = useSelector(selectedActivitiesSelector);
  const overview = useSelector(activityOverviewSelector);
  const organizationId = useSelector(organizationIdSelector);
  const deleting = useSelector(activitiesDeletingSelector);
  const sending = useSelector(activitiesSendingSelector);
  const allActivitySeriesIds = useSelector(allActivitySeriesIdsSelector);

  const dispatch = useDispatch();

  const [sendingToReview, setSendingToReview] = useState(false);

  const selectedRowIds = useMemo(() => {
    return (selectedRowKeys || [])?.filter((seriesId) => {
      return allActivitySeriesIds.includes(seriesId);
    });
  }, [allActivitySeriesIds, selectedRowKeys]);

  const selectedRows = useMemo(() => {
    return selectedRowIds.map((seriesId) => overview.rowData[seriesId]);
  }, [Object.keys(overview.rowData).length, selectedRowIds]);

  const selectedActivityContainsWeekRangeActivity = useMemo(() => {
    return selectedRows.some((item) => item.weeks.includes(weekRangeChar));
  }, [selectedRows]);

  const {
    open: prefPopoverOpen,
    setOpen: setPrefPopoverOpen,
    title: prefPopoverTitle,
  } = useSendToReviewPopover({
    selectedRowKeys: selectedRowIds,
    activitySeries: selectedRows as TActivityResultsInResponse[],
  });

  const selectedItemsCount = useMemo(() => selectedRowIds?.length ?? 0, [selectedRowIds]);

  const onSendToReview = async () => {
    if (!selectedRowIds) return;
    const activitySeriesIds = compact(
      selectedRowIds.map((item) => {
        return overview.rowData[item]?.activitySeriesId;
      }),
    );
    setSendingToReview(true);
    await generateFormInstances(organizationId!, uniq(activitySeriesIds));

    dispatch(changeSelectedActivityIds([]));
    setSendingToReview(false);
  };

  const onExportActivities = async () => {
    if (!selectedRowIds) return;
    await triggerDownloadActivities(organizationId!, selectedRowIds, activityGroupBy);
  };

  const onSendToAM = () => {
    dispatch(publishActivities(selectedRowIds));
  };

  const onDelete = () => {
    dispatch(deleteActivities(selectedRowIds));
  };

  return (
    <>
      {activityGroupBy === 'ACTIVITY_SERIES'
        ? getInlineString('activities.overview.table.number_of_activity_series_selected', selectedItemsCount)
        : getInlineString('activities.overview.table.number_of_activites_selected', selectedItemsCount)}
      <Popconfirm
        disabled={!selectedItemsCount}
        title={prefPopoverTitle}
        onConfirm={onSendToReview}
        okText={
          <div className="te-flex te-items-center">
            <img alt="te-prefs" src={PreferencesIcon} width={16} height={16} />
            <span className="te-ml-2 te-mr-2">Send</span>
          </div>
        }
        okButtonProps={{
          style: { verticalAlign: 'middle', display: 'inline-flex', alignItems: 'center' },
        }}
        cancelButtonProps={{ style: { verticalAlign: 'middle' } }}
        open={prefPopoverOpen}
        onOpenChange={setPrefPopoverOpen}
        icon={null}
      >
        {sendToReview ? (
          <Tooltip
            title={
              selectedActivityContainsWeekRangeActivity
                ? language['activities.overview.table.send_to_pref_not_for_ranges']
                : ''
            }
          >
            <Button
              loading={sendingToReview}
              disabled={!selectedRowIds.length || activityActionsDisabled || selectedActivityContainsWeekRangeActivity}
              size="small"
              className="te-ml-2 te-flex te-items-center"
              data-testid="REVIEW_BUTTON"
            >
              <img alt="te-prefs" src={PreferencesIcon} width={16} height={16} />
              &nbsp;
              {language['activities.overview.table.send_to_review_button']}
            </Button>
          </Tooltip>
        ) : null}
      </Popconfirm>
      <Popconfirm
        disabled={!selectedRowKeys?.length}
        onConfirm={onSendToAM}
        title={getInlineString(
          activityGroupBy === EActivityGroupings.ACTIVITY_SERIES
            ? 'activities.overview.table.publish_confirmation'
            : 'activities.overview.table.publish_activities_confirmation',
          selectedItemsCount,
        )}
        okText={language['activities.overview.table.publish_confirm_button_in_modal']}
        okButtonProps={{
          icon: <img alt="te-core" src={CoreIcon} width={16} height={16} style={{ verticalAlign: 'sub' }} />,
        }}
        cancelText={language.cancel}
        icon={null}
      >
        <Button
          disabled={!selectedItemsCount || activityActionsDisabled}
          size="small"
          className="te-ml-2 te-flex te-items-center"
          loading={sending}
          data-testid="SEND_BUTTON"
        >
          <img alt="te-core" src={CoreIcon} width={16} height={16} />
          &nbsp;
          {language['activities.overview.table.publish_confirm_button']}
        </Button>
      </Popconfirm>
      <Popconfirm
        disabled={!selectedItemsCount}
        title={getInlineString(
          activityGroupBy === EActivityGroupings.ACTIVITY_SERIES
            ? 'activities.overview.table.delete_confirmation'
            : 'activities.overview.table.delete_activities_confirmation',
          selectedItemsCount,
        )}
        onConfirm={onDelete}
      >
        <Button
          loading={deleting}
          disabled={!selectedItemsCount || activityActionsDisabled}
          size="small"
          className="te-ml-2"
          data-testid="DELETE_BUTTON"
        >
          {language.delete}
        </Button>
      </Popconfirm>

      <Popconfirm
        title={getInlineString('general.export.items', selectedItemsCount)}
        onConfirm={onExportActivities}
        okText={language['general.export.trigger_export']}
        okButtonProps={{
          icon: <DownloadOutlined />,
        }}
        icon={null}
      >
        <Button size="small" className="te-ml-2" data-testid="EXPORT_BUTTON" disabled={!selectedItemsCount}>
          {language['general.export.title']}
        </Button>
      </Popconfirm>

      <Pagination
        current={pagination.page}
        pageSize={pagination.perPage}
        total={pagination.totalActivities}
        onChange={props.onPaginationChange}
        size="small"
      />
    </>
  );
}

/* eslint-disable no-param-reassign */
import { createSlice, Dispatch, current } from '@reduxjs/toolkit';
import * as activityService from 'activities/services/activities.service';
import { IAuthState, IDefaultReducerState } from 'types/state.interface';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import {
  TActivityResultsInResponse,
  TActivityResultsInResponseGroupByActivitySeries,
  TActivityResultsInResponseGroupByFlat,
  TActivityResultsInResponseGroupByTrack,
  TActivityRowData,
  TActivityRowRecord,
} from '../types/activity.type';
import { ApplicationState } from 'slices';
import { TTemplateReservationField } from '../types/importTemplate.type';
import { TField } from '@timeedit/types/lib/types';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { Dayjs } from 'dayjs';
import TEObjectsService from 'activities/services/TEObjects.service';
import { castArray, pick, omit, uniq, compact, keyBy } from 'lodash';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { TObjectFilterValues } from '@timeedit/ui-components/lib/src/components/ObjectFilter/ObjectFilter.type';
import { TActivity2Fe } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import { TActivityValue } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityValue.type';
import { Children } from 'react';

type TBasicFilterItem = undefined | string | string[] | Dayjs[];
type TCategoriedFilterItem = Record<string, string | string[]>;

type TPagination = {
  page: number;
  perPage: number;
  totalActivities: number;
};
export interface ActivityState {
  activities: TActivityResultsInResponse[];
  allActivitySeriesIds: string[];
  selectedIds?: string[];
  trigger: number;
  pagination: Record<EActivityGroupings.FLAT | EActivityGroupings.ACTIVITY_SERIES, TPagination>;
  filterPeriod?: string[] | Dayjs[];
  filter: {
    status?: EActivityStatus[];
    [key: string]: TBasicFilterItem | TCategoriedFilterItem;
  };
  finalFilterValues?: activityService.TActivityFilterNestedObject;
  expandedRows: string[];
  categoryFilters: Record<string, TObjectFilterValues>;
  changedCategories: string[];
  loadings: {
    fetch: IDefaultReducerState;
    delete: IDefaultReducerState;
    send: IDefaultReducerState;
  };
  groupBy: TActivityGroupBy;
  selectedActivityId?: string;
  activitySeries?: string[];
  activityIds?: string[];
  overview: {
    series?: TActivityRowRecord[]; // keep basic information of activity series: { id: string, children: any }
    activities?: TActivityRowRecord[]; // keep basic information of activities: { id: string }
    rowData: Record<string, Omit<TActivityRowData, 'activityReviewStatus'>>; // all series, tracks and activities details
    allOtherValues: string[];
    allObjects: string[];
    allFields: string[];
    triggers: {
      ids: string[];
      tracker: number;
    };
  };
}

export const initialState: ActivityState = {
  activities: [],
  allActivitySeriesIds: [],
  selectedIds: [],
  pagination: {
    FLAT: {
      page: 1,
      perPage: 100,
      totalActivities: 1,
    },
    ACTIVITY_SERIES: {
      page: 1,
      perPage: 100,
      totalActivities: 1,
    },
  },
  filterPeriod: activityService.filterStorage.getDate(),
  filter: {
    status: undefined,
    ...activityService.filterStorage.getFilters(),
  },
  expandedRows: [],
  categoryFilters: {},
  changedCategories: [],
  trigger: 0,
  loadings: {
    fetch: {
      loading: false,
      hasErrors: false,
    },
    delete: {
      loading: false,
      hasErrors: false,
    },
    send: {
      loading: false,
      hasErrors: false,
    },
  },
  groupBy: EActivityGroupings.ACTIVITY_SERIES,
  overview: {
    rowData: {},
    allOtherValues: [],
    allObjects: [],
    allFields: [],
    triggers: {
      ids: [],
      tracker: 0,
    },
  },
};

const allocationSlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    fetchActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.fetch);
    },
    fetchActivitiesSuccess: (
      state: ActivityState,
      { payload }: { payload: { results: TActivityResultsInResponse[]; totalResults: number; allKeys: string[] } },
    ) => {
      const { results, totalResults, allKeys } = payload;
      finishedLoadingSuccess(state.loadings.fetch);
      state.activities = results;
      state.allActivitySeriesIds = allKeys;
      state.pagination[state.groupBy].totalActivities = totalResults;
      state.changedCategories = [];
      state.expandedRows = [];
      state.overview.rowData = state.overview.rowData || {};
      if (state.groupBy === 'ACTIVITY_SERIES') {
        state.overview.allOtherValues = uniq(
          (results as TActivityResultsInResponseGroupByActivitySeries[]).flatMap((item) =>
            item.allValues.flatMap((sub) => sub.activityValue.extId),
          ),
        );
        state.overview.series = (results as TActivityResultsInResponseGroupByActivitySeries[]).map((item) => {
          state.overview.rowData[item.activitySeriesId] = activityService.convertToActivityOverview(
            item,
            state.overview.allOtherValues,
          );
          return {
            id: item.activitySeriesId,
            children: new Array(item.numberOfTracks).fill(undefined).map((_item, itemIndex) => ({
              id: activityService.convertToTrackId(item.activitySeriesId, 1 + itemIndex),
            })),
            level: 'series',
          };
        });
      } else {
        state.overview.activities = (results as TActivityResultsInResponseGroupByFlat[]).map((activity) => {
          state.overview.rowData[activity._id] = activityService.convertToActivityOverview(
            activity,
            state.overview.allOtherValues,
          );
          return {
            id: activity._id,
            level: 'activity',
          };
        });
      }

      // Clear old data in overview
      const allDataKeys = [
        ...(state.overview.series?.map(({ id }) => id) || []),
        ...(state.overview.activities?.map(({ id }) => id) || []),
        ...(state.selectedIds || []),
      ];
      Object.keys(state.overview.rowData).forEach((key) => {
        if (!allDataKeys.includes(key)) {
          delete state.overview.rowData[key];
        }
      });
    },
    fetchActivitiesChildrenRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesChildrenFailure: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesChildrenSuccess: (state: ActivityState, { payload }) => {
      const { results, activitySeriesId } = payload;
      state.activities = state.activities.map((activity) => {
        if ((activity as TActivityResultsInResponseGroupByActivitySeries).activitySeriesId === activitySeriesId) {
          (activity as TActivityResultsInResponseGroupByActivitySeries).children = results;
        }
        return activity;
      });
    },

    fetchTracksInSeriesSuccess: (
      state: ActivityState,
      { payload: { tracks } }: { payload: { tracks: TActivityResultsInResponseGroupByTrack[] } },
    ) => {
      isLoadingRequest(state.loadings.fetch);
      const activitySeriesId = tracks?.[0].activitySeriesId;
      if (!activitySeriesId || !state.overview.series) return;
      const foundSeriesIdx = state.overview.series?.findIndex(({ id }) => id === activitySeriesId);
      if (foundSeriesIdx > -1 && state.overview.series) {
        state.overview.series[foundSeriesIdx].children = tracks.map((track) => {
          return {
            id: activityService.convertToTrackId(track.activitySeriesId, track.trackId),
            children: track.activityIds.map((id: string) => ({ id })),
            activitySeriesId,
            trackId: track.trackId,
            level: 'track',
          };
        });
        tracks.forEach((track) => {
          const trackId = activityService.convertToTrackId(track.activitySeriesId, track.trackId);
          state.overview.rowData[trackId] = activityService.convertToActivityOverview(
            {
              ...track,
              allValues: track.allValues.map((item) => ({
                activityValue: item,
              })),
            },
            state.overview.allOtherValues,
          );
        });
      }
      finishedLoadingSuccess(state.loadings.fetch);
    },
    fetchActivitiesInTrackSuccess: (state: ActivityState, { payload: { activities } }) => {
      if (!activities.length) return;
      activities.forEach((activity: TActivity2Fe) => {
        state.overview.rowData[activity._id.toString()] = activityService.convertToActivityOverview(
          activity,
          state.overview.allOtherValues,
        );
      });
      finishedLoadingSuccess(state.loadings.fetch);
    },
    refetchActivitySeriesByIdSuccess: (
      state: ActivityState,
      { payload }: { payload: { results: TActivityResultsInResponseGroupByActivitySeries[] } },
    ) => {
      payload.results.forEach((updatedSeries) => {
        state.overview.rowData[updatedSeries.activitySeriesId] = activityService.convertToActivityOverview(
          updatedSeries,
          state.overview.allOtherValues,
        );
      });
      state.overview.triggers.tracker += 1;
      state.overview.triggers.ids = payload.results.map((series) => series.activitySeriesId);
      finishedLoadingSuccess(state.loadings.fetch);
    },
    refetchActivityByIdSuccess: (state: ActivityState, { payload }: { payload: { results: TActivity2Fe[] } }) => {
      payload.results.forEach((activity) => {
        state.overview.rowData[activity._id] = activityService.convertToActivityOverview(
          activity,
          state.overview.allOtherValues,
        );
      });
      state.overview.triggers.tracker += 1;
      state.overview.triggers.ids = payload.results.map(({ _id }) => _id);
      finishedLoadingSuccess(state.loadings.fetch);
    },

    fetchAndReplaceActivitiesByIdsSuccess: (state: ActivityState, { payload }) => {
      const { results } = payload;
      const identifierProp = state.groupBy === 'ACTIVITY_SERIES' ? 'activitySeriesId' : '_id';
      const resGrouped: Record<string, TActivityResultsInResponse> = keyBy(results, identifierProp);

      const lookupOrOrig = (activity: TActivityResultsInResponse) => {
        if (identifierProp in activity) {
          const res = resGrouped[activity[identifierProp as keyof TActivityResultsInResponse]];
          if (res) {
            return res;
          }
        }
        return activity;
      };

      state.activities = state.activities.map((activity) => {
        return lookupOrOrig(activity);
      });
    },

    publishActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.send);
    },
    publishActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.send);
    },
    publishActivitiesSuccess: (state: ActivityState, { payload }) => {
      state.trigger += 1;
      state.selectedIds = [];
      finishedLoadingSuccess(state.loadings.send);
    },

    deleteActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.delete);
    },
    deleteActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.delete);
    },
    deleteActivitiesSuccess: (state: ActivityState, { payload }) => {
      state.trigger += 1;
      state.selectedIds = [];
      finishedLoadingSuccess(state.loadings.delete);
    },

    changeTablePagination: (
      state: ActivityState,
      { payload: { page, perPage } }: { payload: { page?: number; perPage?: number } },
    ) => {
      if (page !== undefined) {
        state.pagination[state.groupBy].page = page;
      }
      if (perPage !== undefined) {
        state.pagination[state.groupBy].perPage = perPage;
      }
    },

    changeFilterValue: (state: ActivityState, { payload }) => {
      const deletedKeys = Object.keys(state.filter).filter((item) => !payload[item]);
      const updatedFilter = {
        ...omit(
          {
            ...state.filter,
            ...payload,
          },
          deletedKeys,
        ),
      };
      if (JSON.stringify({ ...state.filter }) !== JSON.stringify(updatedFilter)) {
        state.filter = updatedFilter;
      }
    },

    changeFilterPeriodValue: (state: ActivityState, { payload }) => {
      state.filterPeriod = payload;
    },

    setFinalFilters: (state: ActivityState, { payload }: { payload: activityService.TActivityFilterNestedObject }) => {
      state.finalFilterValues = payload;
    },

    changeExpandedRows: (state: ActivityState, { payload }: { payload: { rowId: string; expanded?: boolean } }) => {
      const { rowId, expanded } = payload;
      if (expanded) {
        state.expandedRows = uniq([...state.expandedRows, rowId]);
      } else {
        state.expandedRows = state.expandedRows.filter((id) => id !== rowId);
      }
    },

    changeSelectedActivityIds: (state: ActivityState, { payload }: { payload: string[] }) => {
      state.selectedIds = payload;
    },

    changeActivityGroupBy: (state: ActivityState, { payload }: { payload: TActivityGroupBy }) => {
      state.groupBy = payload;
    },

    setSelectedActivityId: (state: ActivityState, { payload }: { payload?: string }) => {
      state.selectedActivityId = payload;
    },

    triggerToFetchActivities: (state: ActivityState) => {
      state.trigger += 1;
    },

    resetOverviewTrigger: (state: ActivityState) => {
      state.overview.triggers.ids = [];
    },
  },
});

export const {
  fetchActivitiesRequest,
  fetchActivitiesFailure,
  fetchActivitiesSuccess,
  fetchActivitiesChildrenRequest,
  fetchActivitiesChildrenFailure,
  fetchActivitiesChildrenSuccess,

  fetchTracksInSeriesSuccess,
  fetchActivitiesInTrackSuccess,
  refetchActivitySeriesByIdSuccess,
  refetchActivityByIdSuccess,
  fetchAndReplaceActivitiesByIdsSuccess,

  publishActivitiesRequest,
  publishActivitiesFailure,
  publishActivitiesSuccess,

  deleteActivitiesRequest,
  deleteActivitiesFailure,
  deleteActivitiesSuccess,

  changeTablePagination,
  changeSelectedActivityIds,
  triggerToFetchActivities,
  changeFilterValue,
  changeFilterPeriodValue,
  setFinalFilters,
  changeExpandedRows,
  changeActivityGroupBy,
  setSelectedActivityId,
  resetOverviewTrigger,
} = allocationSlice.actions;

export type TActivityGroupBy = EActivityGroupings.FLAT | EActivityGroupings.ACTIVITY_SERIES;
export type TExportActivityOptions = {
  showDmStatusFilter?: boolean;
  examFlowV3?: boolean;
};

/* fetch activities and activity series for table
 * Idea: simplify table data, there is only basic data ( id, children, ...othersRelatedData ) for table dataSources.
 * All activity details can be read from overview.rowData. And that overview data can be used for drawers as well
 * If there is anything change, only need to update overiew.rowData[theID]
 * Important: Make sure overview.rowData always be up-to-date
 * */
export const fetchActivities =
  (groupBy: TActivityGroupBy, options: TExportActivityOptions) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return;
      dispatch(fetchActivitiesRequest());
      const filter = { ...(storeState.activity?.filter ?? {}) };

      if (!options.showDmStatusFilter) {
        delete filter.status;
      }

      const groupedCategories: Record<string, string[]> = {};
      Object.keys(filter).forEach((filterKey) => {
        const { category, field } = activityService.keyToObject(filterKey);
        if (category === 'category') {
          groupedCategories[field] = [...(groupedCategories[field] || []), filterKey];
          // delete filter[filterKey];
        }
      });

      const allCategoryKeys = Object.keys(groupedCategories);
      for (const filterKey of allCategoryKeys) {
        const values = groupedCategories[filterKey].map((fieldKey) => {
          const { key } = activityService.keyToObject(fieldKey);
          return {
            fieldId: TEObjectsService.fields[key]?.id,
            values: castArray(filter[fieldKey] as string[]).map((item) => {
              if (typeof item === 'boolean') return item ? '1' : '0';
              return item.toString();
            }),
          };
        });
        // eslint-disable-next-line no-await-in-loop
        let results = await TEObjectsService.searchObjectsByExactFields(filterKey, values);
        // @ts-ignore
        if (!results?.length) results = [{ extId: 'EMPTY_OBJECT ' }]; // Keep this one to make sure it's always being inside api body

        filter[activityService.objectToKey({ category: 'objects', key: filterKey })] = results.map(
          ({ extId }) => extId,
        ) as string[];
      }

      const ssp = {
        groupBy: groupBy || 'ACTIVITY_SERIES',
        matchType: 'ALL',
        page: storeState.activity?.pagination[storeState.activity.groupBy].page,
        limit: storeState.activity?.pagination[storeState.activity.groupBy].perPage,
        filters: activityService.convertToFetchActivitiesFilters({
          ...filter,
          date: storeState.activity.filterPeriod,
        }),
      };

      if (!options.examFlowV3) {
        ssp.filters = pick(ssp.filters, ['status']);
      }
      if (!options.showDmStatusFilter) {
        ssp.filters = omit(ssp.filters, ['status']);
      }

      dispatch(setFinalFilters(ssp.filters));

      console.log('Activities filter: ', ssp.filters);
      const response = await activityService.getActivities(organizationId, ssp);
      const allObjectsAndFields = activityService.getAllObjectsAndFieldsInActivityValues(
        response.results.flatMap((item: TActivityResultsInResponse) => {
          if ((item as TActivityResultsInResponseGroupByActivitySeries).allValues) {
            return (item as TActivityResultsInResponseGroupByActivitySeries).allValues.map(
              (sub) => sub.activityValue as TActivityValue,
            );
          }
          return (item as TActivityResultsInResponseGroupByFlat).values;
        }),
      );
      await Promise.all([
        TEObjectsService.getObjectTypes(uniq(allObjectsAndFields.objectTypes)),
        TEObjectsService.getObjects(uniq(allObjectsAndFields.objects)),
        TEObjectsService.getFields(uniq(allObjectsAndFields.fields)),
      ]);

      dispatch(fetchActivitiesSuccess(response));
    } catch (e) {
      dispatch(fetchActivitiesFailure());
    }
  };

export const fetchActivitiesChildren =
  (activitySeriesId: string) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesId) return;
      const ssp = {
        groupBy: 'FLAT',
        filters: {
          ...(storeState.activity.finalFilterValues || {}),
          activitySeriesId: { values: [activitySeriesId] },
        },
      };
      dispatch(fetchActivitiesChildrenRequest());
      await activityService.getActivities(organizationId, ssp);
    } catch (e) {
      dispatch(fetchActivitiesChildrenFailure());
      console.error(e);
    }
  };

export const fetchTracksInSeries =
  (seriesId: string) => async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return null;
      dispatch(fetchActivitiesRequest());
      const response = await activityService.getTracksInSeries(organizationId, seriesId);
      dispatch(fetchTracksInSeriesSuccess(response));
      return response;
    } catch (e) {
      dispatch(fetchActivitiesFailure());
      return e;
    }
  };

export const fetchActivitiesInTrack =
  (seriesId: string, trackNumber: number) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return null;
      dispatch(fetchActivitiesRequest());
      const ssp = {
        groupBy: 'FLAT',
        filters: {
          ...(storeState.activity.finalFilterValues || {}),
          activitySeriesId: {
            values: [seriesId],
          },
          track: {
            values: [trackNumber],
          },
        },
      };
      const response = await activityService.getActivities(organizationId, ssp);
      dispatch(
        fetchActivitiesInTrackSuccess({
          activities: response.results,
        }),
      );
      return response;
    } catch (e) {
      dispatch(fetchActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export const refetchActivitySeriesById =
  (activitySeriesIds: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds?.length) return;
      const ssp = {
        groupBy: 'ACTIVITY_SERIES',
        filters: {
          ...(storeState.activity.finalFilterValues || {}),
          activitySeriesId: { values: activitySeriesIds },
        },
      };
      dispatch(fetchActivitiesRequest());
      const seriesResponse = await activityService.getActivities(organizationId, ssp);
      const fetchedSeries = seriesResponse.results[0];
      dispatch(refetchActivitySeriesByIdSuccess(seriesResponse));
      if (!storeState.activity.expandedRows.includes(seriesResponse.results[0]?.activitySeriesId)) return;

      await Promise.all(
        activitySeriesIds.map(async (activitySeriesId: string) => {
          const tracksInSeries = new Array(fetchedSeries.numberOfTracks)
            .fill(null)
            .map((_item, itemIndex) => {
              return 1 + itemIndex;
            })
            .filter((trackId) =>
              storeState.activity.expandedRows.includes(activityService.convertToTrackId(activitySeriesId, trackId)),
            );
          const [tracksResponse, otherActivitiesInTrackResponse] = await Promise.all(
            compact([
              activityService.getTracksInSeries(organizationId!, activitySeriesId),
              tracksInSeries.length
                ? activityService.getActivities(organizationId, {
                    ...ssp,
                    groupBy: 'FLAT',
                    filters: {
                      ...ssp.filters,
                      track: {
                        values: tracksInSeries,
                      },
                    },
                  })
                : null,
            ]),
          );
          if (tracksResponse) {
            dispatch(fetchTracksInSeriesSuccess(tracksResponse));
          }
          if (otherActivitiesInTrackResponse) {
            dispatch(fetchActivitiesInTrackSuccess({ activities: otherActivitiesInTrackResponse.results }));
          }
          return null;
        }),
      );
    } catch (error) {
      fetchActivitiesFailure();
    }
  };

export const refetchActivityById =
  (activityIds: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return;
      dispatch(fetchActivitiesRequest());
      const ssp = {
        groupBy: 'FLAT',
        filters: { id: { values: activityIds } },
      };
      const response = await activityService.getActivities(organizationId, ssp);
      // const updatedActivity = await activityService.getActivityById(organizationId, activityId);
      dispatch(refetchActivityByIdSuccess(response));
    } catch {
      dispatch(fetchActivitiesFailure());
    }
  };

export const fetchAndReplaceActivitiesByIds =
  (ids: string[], onlyFetchThoseIdsWhichAreInCurrentActivities: boolean) =>
  async (dispatch: Dispatch, getState: () => { auth: IAuthState; activity: ActivityState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return;

      const { activities, groupBy } = storeState.activity;

      let idsToFetch = ids;

      if (onlyFetchThoseIdsWhichAreInCurrentActivities) {
        idsToFetch = [];
        for (const a of activities) {
          if ('_id' in a && ids.includes(a._id)) {
            idsToFetch.push(a._id);
          }
          const childrenConcerned = 'activityIds' in a ? a.activityIds?.filter((c: string) => ids.includes(c)) : [];
          idsToFetch.push(...(childrenConcerned ?? []));
        }
      }

      const ssp = {
        groupBy,
        filters: { id: { values: idsToFetch } },
      };

      if (idsToFetch.length > 0) {
        const response = await activityService.getActivities(organizationId, ssp);
        dispatch(fetchAndReplaceActivitiesByIdsSuccess(response));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const publishActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(publishActivitiesRequest());
      const response = await activityService.publishActivities(
        organizationId,
        activitySeriesIds,
        storeState.activity.groupBy,
      );
      dispatch(publishActivitiesSuccess(response));
      return response;
    } catch (e) {
      dispatch(publishActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export const deleteActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(deleteActivitiesRequest());
      const response = await activityService.deleteActivities(
        organizationId,
        activitySeriesIds,
        storeState.activity.groupBy,
      );
      dispatch(deleteActivitiesSuccess(response));
      return response;
    } catch (e) {
      dispatch(deleteActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export default allocationSlice.reducer;

// SELECTORS
export const activitiesSelector = (state: { activity: ActivityState }): TActivityResultsInResponse[] =>
  state.activity.activities;
export const activityOverviewSelector = (state: { activity: ActivityState }): ActivityState['overview'] =>
  state.activity.overview;
export const allActivitySeriesIdsSelector = (state: { activity: ActivityState }): string[] =>
  state.activity.allActivitySeriesIds;
export const selectedActivitiesSelector = (state: { activity: ActivityState }) => state.activity.selectedIds;
export const activitiesLoadingSelector = (state: { activity: ActivityState }) => state.activity.loadings.fetch.loading;
export const activitiesSendingSelector = (state: { activity: ActivityState }) => state.activity.loadings.send.loading;
export const activitiesDeletingSelector = (state: { activity: ActivityState }) =>
  state.activity.loadings.delete.loading;
export const activitiesPaginationSelector = (state: { activity: ActivityState }) =>
  state.activity.pagination[state.activity.groupBy];
export const activitiesTriggerSelector = (state: { activity: ActivityState }) => state.activity.trigger;

export const activityGroupBySelector = (state: { activity: ActivityState }) => state.activity.groupBy;

export const activitySelectedActivityIdSelector = (state: { activity: ActivityState }) =>
  state.activity.selectedActivityId;

export const activityFiltersSelector = (state: { activity: ActivityState }) => state.activity.filter;

export const activityFilterPeriodSelector = (state: { activity: ActivityState }) => state.activity.filterPeriod;

export const activitiesFinalFilterValuesSelector = (state: { activity: ActivityState }) =>
  state.activity.finalFilterValues;

export const activityExpandedRowsSelector = (state: { activity: ActivityState }) => state.activity.expandedRows;

export const reservationFieldsForTemplateSelector =
  (mode: string) =>
  (state: ApplicationState): TTemplateReservationField[] => {
    const reservationFields = state.integration.reservationFields[mode];
    if (!reservationFields) return [];
    return reservationFields?.map((field: TField) => {
      return {
        ...field,
        label: field.name,
        excludeInScheduling: false,
        valueType: 'STRING',
      };
    });
  };

import React, { useEffect, useMemo, useState } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { useActivitySeriesGetter } from './ActivityDrawer.hook';
import { ActivityInfo, ActivityNumberOfTracks } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks } from './ActivityValues';
import intl from 'i18n/intl';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { updateActivitiesValues, updateActivitySeriesWeeks } from 'activities/services/activities.service';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import ActivityTags from './ActivityTags';
import ActivityWeeks from './ActivityWeeks';
import ActivityAddingNewTracksModal from './ActivityAddingNewTracksModal';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import TEObjectsService from 'activities/services/TEObjects.service';
import { Typography, Modal } from 'antd';
import { TWeekSelectorValue } from '../WeekSelector/WeekSelector';
import { useSelector } from 'react-redux';
import { organizationIdSelector } from 'slices/auth.slice';

const language = intl.messages as Record<string, string>;

function ActivitySeriesDrawer() {
  const organizationId = useSelector(organizationIdSelector);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modal, modalContextHolder] = Modal.useModal();
  const [activitySeriesId, setActivitySeriesId] = useState<undefined | string>(undefined);
  const { activitySeries, loading, activityValues, multipleValuesExtIds, initialValues, allDateRanges } =
    useActivitySeriesGetter({
      activitySeriesId,
    });

  const editable = useMemo(() => {
    if (!activitySeries) return false;
    return activitySeries.activityStatuses.every(
      (status) => ![EActivityStatus.IN_REVIEW].includes(status as EActivityStatus),
    );
  }, [activitySeries]);

  const onSave = async (updatedValues: Record<string, string>) => {
    if (!activitySeries || !activitySeries.formId) return;
    setSaving(true);
    await updateActivitiesValues(
      activitySeries.formId.toString(),
      [activitySeries.activitySeriesId],
      EActivityGroupings.ACTIVITY_SERIES,
      updatedValues,
    );
    setSaving(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const onOpen = (e: Event) => {
    setOpen(true);
    setActivitySeriesId((e as CustomEvent<{ activitySeriesId: string }>).detail?.activitySeriesId);
  };

  const onClose = () => {
    setOpen(false);
    document.dispatchEvent(new CustomEvent(ActivityEvents.CLOSE_ACTIVITY_SERIES_DRAWER));
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onOpen);
    return () => {
      document.removeEventListener(ActivityEvents.OPEN_ACTIVITY_SERIES_DRAWER, onOpen);
    };
  }, []);

  useEffect(() => {
    if (!open) setActivitySeriesId(undefined);
  }, [open]);

  const onRequestToChangeWeeks = (weeks: TWeekSelectorValue[], callback?: (_weeks: TWeekSelectorValue[]) => void) => {
    return new Promise((resolve, reject) => {
      modal.confirm({
        title: language['activities.confirm_changing_weeks_of_series.title'],
        content: language['activities.confirm_changing_weeks_of_series.content'],
        onOk: async () => {
          try {
            if (organizationId && activitySeriesId) {
              await updateActivitySeriesWeeks(organizationId, activitySeriesId, weeks);
            }
            if (typeof callback === 'function') callback(weeks);
            resolve(undefined);
          } catch (err) {
            reject(err);
          }
        },
        onCancel: () => reject(new Error('Reject to submit weeks change, keep editing')),
      });
    });
  };

  return (
    <ActivityDrawerWrapper
      saving={saving}
      open={open}
      onClose={() => onClose()}
      title={`${activitySeries ? [TEObjectsService.getObjectLabel(activitySeries.primaryObject), TEObjectsService.getObjectLabel(activitySeries.activityType)].join(' - ') : 'N/A'} x${activitySeries?.numberOfTracks}`}
      subtitle={language['activites.activity_series']}
      initialValues={initialValues}
      primaryObject={activitySeries?.primaryObject}
      editable={editable}
      onSave={onSave}
      loading={loading}
    >
      <>
        <ActivityInfo editable={editable} loading={loading} activityType={activitySeries?.activityType} />
        <ActivityNumberOfTracks totalTracks={activitySeries?.numberOfTracks} />
        {editable && activitySeries && (
          <ActivityAddingNewTracksModal
            activitySeries={activitySeries}
            activityValues={activityValues}
            initialValues={initialValues}
            onClose={onClose}
          />
        )}
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activityValues}
          title={language.track_info}
          multipleValuesExtIds={multipleValuesExtIds}
        >
          <div className="te-mb-2">
            <p className="te-mt-2">
              <Typography.Text>{language['activities.warning_when_editing_activities_in_series']}</Typography.Text>
            </p>
          </div>
          <ActivityTracks track={activitySeries?.numberOfTracks} />
          <ActivityWeeks
            allowMultiple
            editable
            allWeeks={allDateRanges}
            numberOfTracks={activitySeries?.numberOfTracks}
            disabledWeeks={[]}
            onSubmit={onRequestToChangeWeeks}
            isBatchSelection
            required
          />
        </ActivityValues>
        <ActivityTags formId={activitySeries?.formId?.toString()} editable={editable} />
        {modalContextHolder}
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivitySeriesDrawer;
